import './SignUp.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {DyButton, DyTextInput} from "../core";
import {createAccount} from "../../api/account.api.ts";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {DoculyHeader} from "@/components/DoculyHeader";
import {NavLink} from "react-router-dom";

export type SignUpProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'sign-up';

interface SignUpUser {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export function SignUp({className, ...props}: SignUpProps) {

    const [accountInfo, setAccountInfo] = useState<SignUpUser>({
        firstName: '',
        email: '',
        lastName: '',
        password: ''
    });
    const [invalid, setInvalid] = useState<boolean>(true);


    useEffect(() => {
        setInvalid(!(
            accountInfo.firstName.trim() &&
            accountInfo.lastName.trim() &&
            accountInfo.email.trim() &&
            accountInfo.password.trim()
        ));
    }, [accountInfo])

    function handleUserChange(e: ChangeEvent<HTMLInputElement>): void {
        setAccountInfo(user => ({
                ...user,
                [e.target.name]: e.target.value
            })
        )
    }

    async function handleCreateAccount() {
        if (!invalid) {
            await createAccount(accountInfo);
            await signInWithEmailAndPassword(getAuth(), accountInfo.email, accountInfo.password);
        }
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DoculyHeader  className={`${baseClassName}__header`}/>
        <main className={`${baseClassName}__content`}>
            <div>First Name</div>
            <DyTextInput name={'firstName'} onChange={handleUserChange} value={accountInfo.firstName}/>
            <div>Last Name</div>
            <DyTextInput name={'lastName'} onChange={handleUserChange} value={accountInfo.lastName}/>
            <div>Email</div>
            <DyTextInput name={'email'} onChange={handleUserChange} value={accountInfo.email}/>
            <div>Password</div>
            <DyTextInput name={'password'} type="password" onChange={handleUserChange} value={accountInfo.password}/>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <DyButton onClick={handleCreateAccount} disabled={invalid}>Create Account</DyButton>
            <NavLink to={'/auth/sign_in'} replace={true}>Already have an account?  Sign In.</NavLink>
        </footer>
    </div>
}

export default SignUp;
