import {defaultAuthenticatedApiClient, defaultPublicApiClient} from "./ApiClient.ts";

export interface CreateAccountParams {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export async function createAccount(user: CreateAccountParams): Promise<DoculyUser> {

    return defaultPublicApiClient.httpPost<DoculyUser>('/accounts.create', user);
}

export interface DoculyAccount {
    user: DoculyUser;
    tenant: DoculyTenant;
}
export async function getAccount(): Promise<DoculyAccount> {

    return defaultAuthenticatedApiClient.httpGet<DoculyAccount>('/me');
}
