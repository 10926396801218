import './bootstrap.ts';
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './styles/index.scss'
import {AppProvider} from "./contexts/AppContext.tsx";
import {BrowserRouter} from "react-router-dom";


console.log('meta', import.meta.env)

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <AppProvider>
                <App/>
            </AppProvider>
        </BrowserRouter>
    </React.StrictMode>,
)
