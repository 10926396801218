import './DashboardNav.scss';
import React from "react";
import classnames from "classnames";
import {useAppContext} from "@/contexts/AppContext.tsx";
import {getAuth} from "firebase/auth";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";

export interface DashboardNavProps extends React.HTMLAttributes<HTMLElement> {

}

const baseClassName = 'dashboard-nav';

export function DashboardNav({className, ...props}: DashboardNavProps) {
    const {user} = useAppContext();

    async function handleLogout(): Promise<void> {

        await getAuth().signOut();
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <nav className={`${baseClassName}__links`}>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--documents`)}>Documents</li>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--clauses`)}>Clauses</li>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--alerts`)}>Alerts</li>
        </nav>
        <footer className={`${baseClassName}__footer`}>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--support`)}>Support</li>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--settings`)}>Settings</li>
            <div className={`${baseClassName}__user`}>
                <div className={`${baseClassName}__user-name`}>{user?.firstName} {user?.lastName}</div>
                <DyTriggerIcon className={`${baseClassName}__logout`} icon={'logout'} onClick={handleLogout}/>
            </div>
        </footer>
    </div>
}

export default DashboardNav;
