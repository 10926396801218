import React, {ReactNode, useEffect, useState} from "react";
import {getAuth} from "firebase/auth";
import {getAccount} from "../api";

export interface AppContextValue {
    user?: DoculyUser;
    tenant?: DoculyTenant;
}
const AppContext = React.createContext<AppContextValue>({});
AppContext.Provider
interface AppProviderProps {
    children: ReactNode | ReactNode[];
}
export function AppProvider({children}: AppProviderProps) {

    const [value, setValue] = useState<AppContextValue>({})

    useEffect(() => {
        return getAuth().onIdTokenChanged(async (fbUser) => {
            if(fbUser) {
                const {user, tenant} = await getAccount();
                setValue(value => ({
                    ...value,
                    user,
                    tenant
                }));
            } else {
                setValue(value => ({
                    ...value,
                    user: undefined,
                    tenant: undefined
                }));
            }
        })
    }, [])

    return <AppContext.Provider value={value}>
        {children}
    </AppContext.Provider>
}

export const useAppContext = () => React.useContext(AppContext);
