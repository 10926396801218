import * as Guards from './guards';
import React, {Children, forwardRef, ReactElement, useImperativeHandle} from 'react';
import {Navigate} from 'react-router-dom';
import {SignUp} from "../components/SignUp";
import {SignIn} from "../components/SignIn";
import {Invitation} from "../components/Invitation";
import {Authentication} from "../views/Authentication";
import {useAppContext} from "../contexts/AppContext.tsx";
import {Dashboard} from "../views/Dashboard";

// todo: move this to build variable so it can vary by environment
const DEFAULT_PAGE_TITLE = 'Doculy AI';

type RouteMetaData = {
    requireAuth?: boolean;
    requireTenant?: boolean;
    [key: string]: unknown;
};

type Guard = (args: GuardArgs) => string | void;

type ElementRouteConfig = {
    path: string;
    element: JSX.Element;
    alias?: string;
    aliases?: string[];
    guard?: Guard;
    children?: RouteConfig[]
    default?: true;
    meta?: RouteMetaData;
    title?: string;
    redirect?: never;
}

type RedirectRouteConfig = {
    path: string;
    redirect: string;
    element?: never;
    guard?: Guard;
}

type RouteGuardArgs = {
    guard?: Guard;
    element: ReactElement;
    [key: string]: any;
}

type RouteConfig = ElementRouteConfig | RedirectRouteConfig;

export type GuardArgs = {
    meta: RouteMetaData;
    user?: DoculyUser;
}

const routeConfigs: RouteConfig[] = [
    {
        path: '/auth',
        // @ts-ignore
        element: <Authentication />,
        guard: Guards.authentication,
        meta: { requireAuth: false },
        children: [
            { path: 'sign_up', element: <SignUp />, default: true },// @ts-ignore
            { path: 'sign_in', element: <SignIn /> },
            { path: 'invitation/:invitationId', element: <Invitation/> },
        ],
    }, {
        path: '/dashboard',
        element: <Dashboard/>,
        guard: Guards.dashboard,
        meta: {
            requireAuth: true
        },
        default: true
    }
];

// @ts-ignore
export const AppRoute = forwardRef(({ children, route, ...props }, ref) => {
    document.title = route.title;
    useImperativeHandle(ref, () => ({
        route,
        component: route.element.type,
    }));

    return Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        } else {
            return child;
        }
    });
});
AppRoute.displayName = 'AppRoute';

export function toRoute(config: RouteConfig, parent?: ElementRouteConfig): RouteConfig[] {
    let { path, element, aliases = [], children, meta = {}, guard, redirect, ...options } = config as ElementRouteConfig;

    (config as ElementRouteConfig).title = (config as ElementRouteConfig).title ?? parent?.title ?? DEFAULT_PAGE_TITLE;

    const paths: any[] = [];

    if (redirect) {
        return [{ path: path, element: <Navigate to={redirect} replace /> }];
    }

    // if this a default path, make "*" and "" aliases
    if (options.default) {
        aliases.push('*', '');
    }

    // create a redirect for each alias
    // todo: make this work for non leaf paths
    aliases.forEach((alias) => paths.push({ path: alias, element: <Navigate to={path} replace /> }));

    if (guard || meta?.requireAuth) {
        element = <RouteGuard guard={guard} element={element} meta={meta} />;
    }

    // @ts-ignore
    element = <AppRoute route={config}>{element}</AppRoute>;

    return [...paths, { path, element, children: children?.map((child) => toRoute(child, config as ElementRouteConfig)).flat() }];
}


export function RouteGuard({ guard, element, meta = {}, ...props }: RouteGuardArgs) {
    const { user } = useAppContext();

    const redirect = guard?.({ meta, user });
    if (redirect) {
        return <Navigate to={redirect} replace />;
    }

    return React.cloneElement(element, props);
}
RouteGuard.displayName = 'RouteGuard';

export const routes = routeConfigs.map(route => toRoute(route)).flat();
