import './DoculyHeader.scss';
import React from "react";
import classnames from "classnames";
import logo from "@images/logos/logo-combo.svg";

export interface DoculyHeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const baseClassName = 'doculy-header';

export function DoculyHeader({className, ...props}: DoculyHeaderProps) {
    return <header className={classnames(baseClassName, className)} {...props}>
        <img className={`${baseClassName}__image-logo`} src={logo} alt={'Doculy Logo'}/>
    </header>
}

export default DoculyHeader;
