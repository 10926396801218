import './DashboardHeader.scss';
import React from "react";
import classnames from "classnames";
import {DyTextInput} from "../../core";
import {useAppContext} from "@/contexts/AppContext.tsx";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";

export interface DashboardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'dashboard-header';

export function DashboardHeader({className, ...props}: DashboardHeaderProps) {

    const {tenant} = useAppContext();

    return <div className={classnames(baseClassName, className)} {...props}>
        <h1 className={`${baseClassName}__tenant-name`}>{tenant?.displayName}</h1>
        <div className={`${baseClassName}__ask-me-anything`}>
            <DyTextInput className={`${baseClassName}__prompt`} placeholder={'Ask any document related question.'}/>
            <DyTriggerIcon icon={'refresh'} className={`${baseClassName}__refresh`}/>
        </div>
    </div>
}

export default DashboardHeader;
