import './DyButton.scss';
import React, {ReactNode} from "react";
import classnames from "classnames";

export interface DyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: ReactNode;
    theme?: 'default' | 'dark'
}

const baseClassName = 'dy-button';

export function DyButton({className, children, label = children, theme = 'default', ...props}: DyButtonProps) {
    return <button className={classnames(baseClassName, className, `${baseClassName}--type-${theme}`, {
        [`${baseClassName}--disabled`]: props.disabled
    })} {...props}>
        {label}
    </button>
}

export default DyButton;
