import './DyTextInput.scss';
import React from "react";
import classnames from "classnames";

export interface DyTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {

}

const baseClassName = 'dy-text-input';

export function DyTextInput({className, ...props}: DyTextInputProps) {
    return <div className={classnames(`${baseClassName}__wrapper`, className)}>
        <input className={`${baseClassName}__input`} {...props}/>
    </div>
}

export default DyTextInput;
