import './Dashboard.scss';
import React from "react";
import classnames from "classnames";
import {DashboardHeader, DashboardNav} from "../../components/dashboard";
import {Outlet} from "react-router";

export interface DashboardProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'dashboard';

export function Dashboard({className, ...props}: DashboardProps) {


    return <div className={classnames(baseClassName, className)} {...props}>
        <DashboardHeader className={`${baseClassName}__header`}/>
        <DashboardNav className={`${baseClassName}__nav`}/>
        <main className={`${baseClassName}__content`}>
            <Outlet/>
        </main>
    </div>
}

export default Dashboard;
