import './SignIn.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {DyButton, DyTextInput} from "../core";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {DoculyHeader} from "@/components/DoculyHeader";
import {NavLink} from "react-router-dom";

export interface SignInProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'sign-in';

interface EmailAndPassword {
    email: string;
    password: string;
}

export function SignIn({className, ...props}: SignInProps) {

    const [credentials, setCredentials] = useState<EmailAndPassword>({
        email: '',
        password: ''
    });
    const [invalid, setInvalid] = useState<boolean>(true);


    useEffect(() => {
        setInvalid(!(
            credentials.email.trim() &&
            credentials.password.trim()
        ));
    }, [credentials])

    function handleUserChange(e: ChangeEvent<HTMLInputElement>): void {
        setCredentials(user => ({
                ...user,
                [e.target.name]: e.target.value
            })
        )
    }

    async function handleLogin() {
        if (!invalid) {
            await signInWithEmailAndPassword(getAuth(), credentials.email, credentials.password);
        }
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DoculyHeader  className={`${baseClassName}__header`}/>
        <main className={`${baseClassName}__content`}>
            <div>Email</div>
            <DyTextInput name={'email'} onChange={handleUserChange} value={credentials.email}/>
            <div>Password</div>
            <DyTextInput name={'password'} type="password" onChange={handleUserChange} value={credentials.password}/>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <DyButton onClick={handleLogin} disabled={invalid}>Sign In</DyButton>
            <NavLink to={'/auth/sign_up'} replace={true}>Don't have an account?  Sign Up.</NavLink>
        </footer>
    </div>
}

export default SignIn;
