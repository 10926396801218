import './Invitation.scss';
import React from "react";
import classnames from "classnames";

export type InvitationProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'invitation';

export function Invitation({className, ...props}: InvitationProps) {
    return <div className={classnames(baseClassName, className)} {...props}>

    </div>
}

export default Invitation;
