import {GuardArgs} from "./routes";

export function authentication({ user }: GuardArgs) {
    if (user) {
        return '/dashboard'
    }
}


export function dashboard({ user}: GuardArgs) {

    if (!user) {
        return '/auth';
    }
}
