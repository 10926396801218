import './Authentication.scss';
import React from "react";
import classnames from "classnames";
import {Outlet} from "react-router";

export interface AuthenticationProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'authentication';

export function Authentication({className, ...props}: AuthenticationProps) {
    return <div className={classnames(baseClassName, className)} {...props}>
        <Outlet />
    </div>
}

export default Authentication;
