import './DyTriggerIcon.scss';
import React from "react";
import classnames from "classnames";

export interface DyTriggerIconProps extends React.HTMLAttributes<HTMLButtonElement> {
    icon: 'refresh' | 'logout' | string;
    theme?: 'light' | 'dark' | 'blue';
}

const baseClassName = 'dy-trigger-icon';

export function DyTriggerIcon({className, icon, theme, ...props}: DyTriggerIconProps) {
    return <button className={classnames(baseClassName, className, `${baseClassName}--${icon}`, `${baseClassName}--theme-${theme}`)}
                   {...props}/>
}

export default DyTriggerIcon;
